<template>
  <div class="layout-main">
    <dot-header />
    <main class="wrapper">
      <aside class="wrapper__sidebar" :class="{collapsed: isMenuCollapsed}" v-if="!isMobile">
        <dot-menu />
      </aside>
      <slot />
    </main>
  </div>
</template>

<script setup>
import {computed} from 'vue';

import DotHeader from '@/components/header/Header.vue';
import DotMenu from '@/components/DotMenu.vue';
import {useStore} from 'vuex';

import useMobileWatcher from '@/composables/useMobileWatcher';
const {isMobile} = useMobileWatcher();

const store = useStore();

const isMenuCollapsed = computed(() => store.getters.isMenuCollapsed);

</script>

<style lang="scss" scoped>

#app {
  @include desktop {
    overflow: hidden !important;
  }
}

</style>