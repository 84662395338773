import {ref, computed, onMounted, onBeforeUnmount} from 'vue';
import {useStore} from 'vuex';

const TABLET_LESS_MORE_BP = 768;

export default function useMobileWatcher(tabletBreakpoint = TABLET_LESS_MORE_BP) {
  const windowWidth = ref(window.innerWidth);
  const store = useStore();

  const isMobile = computed(() => store.state.isMobile);

  const onResize = () => {
    windowWidth.value = window.innerWidth;
    store.commit('setMobile', windowWidth.value < tabletBreakpoint);
  };

  onMounted(() => {
    onResize();
    window.addEventListener('resize', onResize);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('resize', onResize);
  });

  return {
    isMobile,
    windowWidth,
  };
}
