<template>

  <component :is="currentLayout">
    <router-view />
  </component>

</template>

<script setup>
import {shallowRef, watch, onMounted} from 'vue';
import {useRoute} from 'vue-router';
import DefaultLayout from '@/layouts/DefaultLayout';

import {useStore} from 'vuex';

const currentLayout = shallowRef(null);
const route = useRoute();
const store = useStore();

onMounted(() => {
  window.addEventListener('load', () => {
    store.commit('setReady', true);
  });
});

const loadLayout = async (layout) => {
  try {
    if (layout) {
      const layoutModule = await layout();
      currentLayout.value = layoutModule.default;
    } else {
      currentLayout.value = DefaultLayout;
    }
  } catch (error) {
    console.error('Error loading layout:', error);
    currentLayout.value = DefaultLayout;
  }
};

watch(
  () => route.meta.layout,
  async (layout) => {
    if (layout) {
      await loadLayout(layout);
    }
  },
  {immediate: true}
);

</script>

<style lang="scss">
@import '@/assets/scss/main.scss';
</style>
